@import "../../theme.css";

.nav-bar__ui {
  display: flex;
  position: sticky;
  top: -1px;
  align-items: center !important;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
