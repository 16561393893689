.content-wrapper {
  margin-top: 1em;
  text-align: left;
  width: 90vw;
  margin: 1em auto !important;
  border: 2px dashed var(--sec);
  background-color: var(--sec-trans);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.content-wrapper.yes:hover {
  background-color: var(--sec-trans-hover);
  color: var(--comp-hover);
  border-color: var(--comp-hover);
}

.content-wrapper.no {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
