.mail-form {
  transition: 300ms;
  display: flex;
  flex-flow: column nowrap;
  padding: 0.8em 2em;
  color: var(--comp);
  font-weight: bold;
}

.mail-form > input,
textarea,
button {
  background-color: var(--main);
  border: 2px solid var(--comp);
  border-radius: 5px;
  color: inherit;
  padding: 5px;
}
