.grid-list {
  display: flex;
  flex-flow: column wrap;
  flex-basis: 30%;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
}

.list-title {
  text-align: center;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}

.grid-tactical {
  margin-top: 1em;
  display: flex;
  flex-flow: row wrap;
  flex-basis: 40%;
}

.grid-tactical > * {
  flex-grow: 1 !important;
  margin: 1px 1px;
}

.grid-tactical > a {
  text-decoration: none;
  color: var(--comp-hover);
  text-align: center;
  font-size: 1.3em;
  align-self: center;
}

.grid-tactical > img {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  border: 1px solid var(--sec);
}
