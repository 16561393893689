:root {
  --main: #2c2c2c; /* ?Background color and font in the side menu  */
  --shadow: #aaa;
  --sec: #ddd; /* ?Used as background color for nav elements */
  --sec-trans: #dddddd22;
  --sec-trans-hover: #dddddd88;
  --comp: #be8815; /* ?Font color */
  --comp-hover: #fbe818;
}

body {
  color: var(--comp);
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.icon-menu {
  position: absolute;
  font-size: 3em;
  padding: 0.3em;
}

.title {
  width: fill;
  text-align: center;
  margin: 0;
  padding: 0;
}
