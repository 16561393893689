@import "../../theme.css";

.color-picker {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  width: 5em;
  height: 5em;
}

.color-picker__form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  background-color: var(--shadow);
  padding: 10px;
  border-radius: 10px;
}

.color-picker__form > section {
  display: flex;
  flex-direction: column;
  font-weight: bolder;
}

.color-picker__button {
  background-color: var(--main);
  border: 2px solid var(--comp);
  width: 3em;
  height: 3em;
  font-size: 1em;
  color: var(--comp);
}
