@keyframes swipe-left {
  0% {
    right: -60%;
  }
  100% {
    right: 0%;
  }
}

.option-content-wrapper {
  animation: swipe-left 300ms;
  width: 240px;
  height: 300px;
  background-color: var(--sec);
  border-radius: 10px;
  position: relative;
  top: 0.1em;
  padding: 10px;
}
