@import "../../theme.css";

.options-wrapper {
  margin-top: 2em;
}

.options-icon {
  margin: 0 1rem;
  padding: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  font-size: 1.6em;
  position: relative;
  transition: 300ms;
}

.active {
  background-color: var(--sec);
  top: 0.5em;
}
